<template>
  <div
    v-if="isShow()"
    class="button-block"
  >
    <el-button
      v-if="previousId"
      type="primary"
      size="small"
      icon="fas fa-arrow-left"
      @click="redirectToCard(previousId)"
    >
      {{ $companyConfiguration.i18n.t('cards', 'previous_button', 'Предыдущая карта') }}
    </el-button>
    <el-button
      v-if="nextId"
      type="primary"
      size="small"
      @click="redirectToCard(nextId)"
    >
      {{ $companyConfiguration.i18n.t('cards', 'next_button', 'Следующая карта') }}
      <i class="fas fa-arrow-right"></i>
    </el-button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "card-next-and-previous-links",
  components: {},
  props: {},
  computed: {
    ...mapGetters('card', {
      card: 'card',
    }),
  },
  data() {
    return {
      nextId: null,
      previousId: null,
    }
  },
  watch: {
    card: {
      handler(newCard) {
        if (newCard && newCard.card_next_and_previous_ids) {
          this.nextId = newCard.card_next_and_previous_ids.nextId ?? null;
          this.previousId = newCard.card_next_and_previous_ids.previousId ?? null;
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    isShow() {
      return this.$companyConfiguration.card.isCardNextAndPreviousButtons() && (this.nextId || this.previousId);
    },
    redirectToCard(id) {
      const queryParams = this.$route.query;

      this.$router.push({
        name: 'Card',
        params: { id: id },
        query: queryParams,
      }).then(() => {
        window.location.reload();
      });
    }
  }
}
</script>

<style>
  .button-block {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 20px 0;
  }

  .button-block {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }

  .button-block button {
    display: block;
  }

  .button-block button:first-child {
    justify-self: start;
  }

  .button-block button:last-child {
    justify-self: end;
  }

  .button-block .fa-arrow-right {
    margin-left: 5px;
  }
</style>